<template>
	<div :id="block.content.anchor"></div>
</template>

<script>
export default {
	name: 'PanelAnchor',
	props: {
		block: { type: Object, required: true },
	},
	mounted() {
		this.goToAnchor();
	},
	methods: {
		goToAnchor() {
			if (this.$route.hash) {
				const anchor = this.$route.hash.split('?')[0];
				if (window.location.hash.split('?')[0] === anchor) {
					const el = document.getElementById(anchor.slice(1));
					if (el) {
						window.scrollTo(0, el.offsetTop);
					}
				}
			}
		},
	},
};
</script>
